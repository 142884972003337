import { OverviewUI } from "features";

const Overview = () => {
  return (
    <>
      <OverviewUI />
    </>
  );
};

export { Overview };
